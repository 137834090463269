import SimpleLightbox from 'simplelightbox';
import 'simplelightbox/dist/simple-lightbox.min.css';

// const gallery1Ref = document.querySelector('.gallery-swiper-1');
// const gallery2Ref = document.querySelector('.gallery-swiper-2');

let gallery1 = new SimpleLightbox('.gallery-swiper-1 a', {
  // captionsData: 'alt',
  captionDelay: 250,
});
let gallery2 = new SimpleLightbox('.gallery-swiper-2 a', {
  // captionsData: 'alt',
  captionDelay: 250,
});
