new Swiper('.gallery-swiper-1', {
  navigation: {
    prevEl: '.gallery-swiper-1-btn-prev',
    nextEl: '.gallery-swiper-1-btn-next',
  },
  keyboard: {
    enabled: true,
    onlyInViewport: true,
  },
  scrollbar: {
    el: '.gallery-swiper-1-scrollbar',
    draggable: true,
    // dragSize: dragSize,
  },
  slidesPerView: 1,
  slidesPerGroup: 1,
  speed: 400,
  spaceBetween: 20,
  freeMode: true,
  breakpoints: {
    425: {
      slidesPerView: 2,
    },
    768: {
      slidesPerView: 3,
    },
    1200: {
      slidesPerView: 4,
    },
  },
});

new Swiper('.gallery-swiper-2', {
  navigation: {
    prevEl: '.gallery-swiper-2-btn-prev',
    nextEl: '.gallery-swiper-2-btn-next',
  },
  keyboard: {
    enabled: true,
    onlyInViewport: true,
  },
  scrollbar: {
    el: '.gallery-swiper-2-scrollbar',
    draggable: true,
    // dragSize: dragSize,
  },
  slidesPerView: 1,
  slidesPerGroup: 1,
  speed: 800,
  spaceBetween: 28,
  freeMode: true,
  breakpoints: {
    425: {
      slidesPerView: 2,
    },
    768: {
      slidesPerView: 3,
    },
  },
});