import refs from "./refs";

window.addEventListener('scroll', onWindowScroll);

function onWindowScroll() {
  const windowPageYOffset = window.pageYOffset;

  if (windowPageYOffset > 100) {
    refs.body.classList.add('isScrolling');

    if (windowPageYOffset > window.innerHeight - 60) refs.header.classList.remove('header--transparent');
    else if (windowPageYOffset <= window.innerHeight - 60) refs.header.classList.add('header--transparent');

    // if (windowPageYOffset > 500) {
    //   showBtnUp();
    //   showBtnChat();
    // }
    // else if (windowPageYOffset <= 500) {
    //   hideBtnUp();
    //   hideBtnChat();
    // }
  } else if (windowPageYOffset <= 100) refs.body.classList.remove('isScrolling');
}
