import { showElement, hideElement, refs } from "/src/common/js";
import { hideLoader, showLoader } from "../../loader/loader";

const feedbackForm = document.forms.feedbackForm;
const msgSuccess = document.querySelector('.feedback-form__msg--success');
const msgError = document.querySelector('.feedback-form__msg--error');

feedbackForm.addEventListener('submit', onFormSubmit);

function onFormSubmit(e) {
  e.preventDefault();
  sendMail(e.target);
  e.target.reset();
}

async function sendMail(formRef) {
  const formData = new FormData(formRef);
  let sendURL = '/sendmail.php';
  
  showLoader();
  try {
    let response = await fetch(sendURL, {
      method: "POST",
      body: formData,
    });
    if (response.ok) {
      showElement(msgSuccess);
    }
    else showElement(msgError);
  } catch (exceptionVar) {
    showElement(msgError);
  } finally {
    hideLoader();
    const hideMsg = setTimeout(() => {
      hideElement(feedbackForm);
      hideElement(msgError);
      hideElement(msgSuccess);
      clearTimeout(hideMsg);
    }, 5000);
  }
}